@import '@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import '@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as';
@import '@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss';

.currencySelector {
  position: relative;

  .currencyIndicator {
    position: absolute;
    right: 0;
    top: 26px;
    display: flex;
    align-items: center;
    height: 52px;

    .flagWrapper {
      width: 26px;
      height: 20px;
      border: 1px solid $colour-heritage-green-100;
    
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .currencyContainer {
      display: flex;
      align-items: center;
      height: spacing(3);
      margin-left: spacing(1);

      .currencyCode {
        margin-left: spacing(0.5);
        @include display-as('body-m');
      }
    }

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      width: spacing(3);
      height: spacing(3);
      margin-left: spacing(1);
      margin-right: spacing(1);

      img {
        width: 16px;
        height: 10px;
      }
    }
  }
}
