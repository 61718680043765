@import '@JohnLewisPartnership/jlfs-ui-library/dist/styles/breakpoints.scss';

.PreviewBanner {
    position: fixed;
    bottom: 0;
    right: 0;

    margin: 1rem;

    min-width: 50%;
    padding: 0.8rem 1.2rem;
    background-color: orange;
    border-radius: 1rem;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    align-items: center;

    font-size: 1rem;

    >span {
        font-size: 1.2rem;
    }

    >a {
        background-color: white;
        padding: 1rem 1.5rem;
        border-radius: 0.8rem;

        text-decoration: none;
        color: black;
        text-align: center;

        &:hover {
            background-color: rgb(77, 172, 77);
            color: white;
        }
    }

    @include breakpointFS(medium) {
        margin: 2rem;
        padding: 1.5rem 1.8rem;

        font-size: 1.2rem;

        >span {
            font-size: 1.5rem;
        }
    }
}