@import "@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing";
@import "@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as";
@import "@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss";

.listItem {
  margin: 0;
  height: spacing(6) + spacing(0.5);
  padding: 0 spacing(1);
  background-color: $colour-fresh-white-100;
  color: $colour-heritage-green-100;
  &.clearBackgroundColour {
    background-color: $colour-fresh-white-100;
  }
}

.listItem:hover {
  background-color: $colour-taupe-light-100;
  cursor: pointer;
}

.titleListItem {
  background-color: $colour-grey-very-light;
  &:hover {
    cursor: default;
    background-color: $colour-fresh-white-100;
  }
  @include display-as("heading-xs");
}

.titleItem {
  display: flex;
  justify-content: space-between;
}

.listItemContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: 1px;
  border-top-style: solid;
  border-top-color: $colour-taupe-100;

  .currencyInfo {
    margin-left: spacing(0.5);
    color: $colour-heritage-green-100;
  }

  &.removeTopBorder {
    border-top-style: none;
  }
}

.flagWrapper {
  width: 26px;
  height: 20px;
  border: 1px solid $colour-heritage-green-100;

  img {
    width: 100%;
    object-fit: cover;
  }
}
