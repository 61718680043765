@import '@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
@import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/breakpoints.scss";
@import '@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss';

.dropdown {
  position: absolute;
  top: spacing(10);
  right: 0;
  max-height: 520px;
  list-style-type: none;
  width: 288px;
  z-index: 100;

  @include breakpointFS(medium) {
    width: 254px;
  }

  @include breakpointFS(large) {
    width: 283px;
  }

  border: 2px;
  border-color: $colour-heritage-green-100;
  border-style: solid;
  border-radius: 4px;

  margin-left: auto;
  margin-top: spacing(0.5);

  padding: 0;
  margin: 0;
  margin-top: spacing(0.5);
  margin-left: auto;
  overflow: auto;
}
